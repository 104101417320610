import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaMoneyCheckAlt, FaFileInvoiceDollar, FaHome, FaBuilding, FaCity } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import banner from './../img/Banner-site-02.png';
import './../css/PaginaInicial.css';

const PaginaInicial = () => {
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    return (
        <div>
            {/* Seção do banner */}
            <div className="banner">
                <img src={banner} alt="Banner" style={{ width: '100%', height: 'auto' }} />
            </div>

            {/* Seção dos cards */}
            <Container className="cards-container" >
                <Row className="justify-content-center">
                    <Col md={4} data-aos="fade-up">
                        <Card className="text-center shadow-sm">
                            <Card.Body>
                                <FaMoneyCheckAlt style={{ color: '#E6341A', fontSize: '40px' }} />
                                <Card.Title className="mt-3">Portal do PIX</Card.Title>
                                <a href="https://pix.cznet.net.br/login">
                                    <Button variant="outline-danger" style={{ fontWeight: 'bold' }}>
                                        Acessar
                                    </Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} data-aos="fade-up">
                        <Card className="text-center shadow-sm">
                            <Card.Body>
                                <FaFileInvoiceDollar style={{ color: '#E6341A', fontSize: '40px' }} />
                                <Card.Title className="mt-3">Portal Faturas</Card.Title>
                                <a href="https://portal.cznet.net.br/login">
                                    <Button variant="outline-danger" style={{ fontWeight: 'bold' }}>
                                        Acessar
                                    </Button>
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Seção "Nossos serviços" */}
            <div className='services-background'>
                <Container className="services-section sessao-mg">
                    <Row>
                        <Col md={6} className="text-left" data-aos="fade-right">
                            <h6 className="text-danger" style={{ fontFamily: 'Arial, sans-serif' }}>Nossos serviços</h6>
                            <div style={{ height: '20px' }}></div> {/* Espaço */}
                            <h2 className="text-black" style={{ fontWeight: 'bold' }}>Conectividade de alta performance para todos os segmentos</h2>
                            <p className="text-muted mt-4" style={{ textAlign: 'justify', color: '#6c757d' }}>
                                Navegue com CZnet e desfrute da melhor conexão, seja em casa ou nos negócios. Velocidade, confiabilidade e suporte, sempre ao seu lado.
                            </p>
                        </Col>

                        {/* Cards estilizados em formato piramidal */}
                        <Col md={6} style={{ marginTop: '40px' }}>
                            <Row className="justify-content-center">
                                <Col md={4} className="service-card" data-aos="fade-left">
                                    <Card className="text-center shadow-sm custom-card">
                                        <Card.Body>
                                            <div className="icon-circle">
                                                <FaHome style={{ color: 'white', fontSize: '30px' }} />
                                            </div>
                                            <Card.Title className="mt-3">Residencial</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className="service-card middle-card" data-aos="fade-down">
                                    <Card className="text-center shadow-sm custom-card" style={{ marginTop: '-30px' }}>
                                        <Card.Body>
                                            <div className="icon-circle">
                                                <FaBuilding style={{ color: 'white', fontSize: '30px' }} />
                                            </div>
                                            <Card.Title className="mt-3">Corporativa</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className="service-card" data-aos="fade-right">
                                    <Card className="text-center shadow-sm custom-card">
                                        <Card.Body>
                                            <div className="icon-circle">
                                                <FaCity style={{ color: 'white', fontSize: '30px' }} />
                                            </div>
                                            <Card.Title className="mt-3">Empresarial</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default PaginaInicial;
